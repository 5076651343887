import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@lib'
import { Buttons } from '@shared/Action'
import { Group, Input } from '@shared/Form'
import Modal from '@lib/Modal'
import { Can } from '@caslConfig/can.js'

const RoleSelectorModal = ({ sites, clientGroups, roles, disabled, setState, getState, getStateGroups, setStateGroups, siteDatalogicAccess, setSiteDatalogicAccess }) => {
  const [showRoleSelectorModal, setShowRoleSelectorModal] = useState(false)
  const [siteRoles, setSiteRoles] = useState(JSON.parse(JSON.stringify(getState)))
  const [siteGroups, setSiteGroups] = useState(JSON.parse(JSON.stringify(getStateGroups)))
  const [setAllAs, setSetAllAs] = useState({ role: null, group: null, datalogic: null })
  const [setAllGroupOptions, setSetAllGroupOptions] = useState([])
  const [refreshed, setRefreshed] = useState(false)

  useEffect(() => {
    const toRemove = []
    siteRoles?.forEach(sr => {
      if (!sites.find(s => s.value === sr.value)) {
        toRemove.push(sr.value)
      }
    })
    if (toRemove.length > 0) {
      setSiteRoles(siteRoles.filter(sr => !toRemove.includes(sr.value)))
    }
  }, [sites])

  useEffect(() => {
    if (refreshed) {
      setRefreshed(false)
    }
  }, [refreshed])

  useEffect(() => {
    if (clientGroups.length > 0 && sites.length > 0) {
      let allGroupOptions = []
      clientGroups.forEach(cg => {
        const groupText = cg.text.replace(/ *\([^)]*\) */g, '')
        const alternateIds = clientGroups.filter(cgf => cgf.text.replace(/ *\([^)]*\) */g, '') === groupText && cgf.value !== cg.value).map(cgg => cgg.value)
        if (alternateIds.length > 0) {
          alternateIds.push(cg.value)
          if (!allGroupOptions.find(ag => ag.text === groupText)) {
            allGroupOptions.push({ text: groupText, value: groupText, altIds: alternateIds })
          }
        }
      })

      allGroupOptions = allGroupOptions.sort((a, b) => a.text.localeCompare(b.text))

      setSetAllGroupOptions(allGroupOptions)
    }
  }, [sites])

  useEffect(() => {
    if (showRoleSelectorModal) {
      setSiteRoles(JSON.parse(JSON.stringify(getState)))
      setSiteGroups(JSON.parse(JSON.stringify(getStateGroups)))
      setSiteDatalogicAccess(JSON.parse(JSON.stringify(siteDatalogicAccess)))
      setRefreshed(true)
    }
  }, [showRoleSelectorModal])

  useEffect(() => {
    if (getStateGroups.length > 0) {
      setSiteGroups(getStateGroups)
    }
  }, [getStateGroups])

  const handleRoleChange = (site, value) => {
    const newSiteRoles = [...siteRoles]
    if (newSiteRoles.find(nsr => nsr.value === site.value)) {
      newSiteRoles[newSiteRoles.indexOf(newSiteRoles.find(nsr => nsr.value === site.value))].siteRoleId = value
    } else {
      newSiteRoles.push({
        ...site,
        siteRoleId: value
      })
    }
    setSiteRoles(newSiteRoles)
  }

  const handleSetAllAs = (name, value) => {
    if (name === 'group') {
      const newGroupIds = setAllGroupOptions.find(ag => ag.value === value).altIds
      setSiteGroups(newGroupIds)
    } else if (name === 'role') {
      const newSiteRoles = [...sites].map(s => {
        return {
          ...s,
          siteRoleId: value
        }
      })
      setSiteRoles(newSiteRoles)
    } else if (name === 'datalogic') {
      const newSiteDatalogicAccess = {}
      sites.forEach(site => {
        newSiteDatalogicAccess[site.value] = value
      })

      document.querySelectorAll('.enable-datalogic .datalogic-toggle .toggle-input').forEach(el => {
        el.classList.toggle('active', value)
        el.setAttribute('value', value)
      })

      setSiteDatalogicAccess(newSiteDatalogicAccess)
      setRefreshed(true)
    }
    setSetAllAs(prevState => ({ ...prevState, [name]: value }))
  }

  const handleSaveRoles = () => {
    setState(JSON.parse(JSON.stringify(siteRoles)))
    setStateGroups(JSON.parse(JSON.stringify(siteGroups)))
    setShowRoleSelectorModal(false)
    setRefreshed(true)
  }

  const handleDatalogicToggle = (siteId, value) => {
    document.querySelector('.set-all-row .datalogic-toggle .toggle-input')?.classList.remove('active')
    document.querySelector('.set-all-row .datalogic-toggle .toggle-input')?.setAttribute('value', false)

    setSiteDatalogicAccess(prev => ({
      ...prev,
      [siteId]: value
    }))
    setSetAllAs(prevState => ({ ...prevState, datalogic: false }))
    setRefreshed(true)
  }

  return (
    <>
    <Group>
      <div className={`site-role-selector ${disabled ? 'disabled' : ''}`} onClick={() => !disabled && setShowRoleSelectorModal(!showRoleSelectorModal)}>
        <p>Site Role and User Group Selector</p>
      </div>
    </Group>
    <Modal
      show={showRoleSelectorModal}
      allowClickOutside={false}
      hasCloseButton={false}
      className='site-role-selector-modal'
    >
      <div className='content site-role-selector-content'>
        <h2>Site Role and User Group Selector</h2>
        <div className='site-role-table'>
          {sites.length > 1 &&
            <div className='site-role-table-row set-all-row'>
              <div className='col col-head'>Set All As</div>
              <Input
                type='select'
                options={roles}
                placeholder='Select role'
                value={setAllAs.role}
                onChange={(v) => handleSetAllAs('role', v)}
              />
              <Input
                type='select'
                options={setAllGroupOptions}
                placeholder='Select group'
                value={setAllAs.group}
                onChange={(v) => handleSetAllAs('group', v)}
              />
              <div className='datalogic-toggle-container'>
                <p>Set All DataLogic Access</p>
                <Input
                  type='toggle'
                  className='datalogic-toggle'
                  value={setAllAs.datalogic || false}
                  onChange={(v) => handleSetAllAs('datalogic', v)}
                />
              </div>
            </div>
          }
          <div className='site-role-table-row'>
            <div className='col col-head'>Site</div>
            <div className='col col-head'>Site Role</div>
            <div className='col col-head'>User Group</div>
            <div className='col col-head'>Datalogic User</div>
          </div>
          {!refreshed && sites?.map((x, key) =>
            <div key={key} className={`site-role-table-row site-id-${x.value}`}>
              <div className='col'>{x.text}</div>
              <div className='col'>
                <Input
                  type='select'
                  options={roles}
                  placeholder='Select role'
                  value={siteRoles.find(sr => sr.value === x.value)?.siteRoleId}
                  onChange={(v) => handleRoleChange(x, v)}
                />
              </div>
              <div className='col'>
                <Input
                  type='multiselect'
                  options={clientGroups.filter(cg => cg.text.includes('(' + x.text + ')'))}
                  placeholder='Select group'
                  value={siteGroups}
                  onChange={(v) => setSiteGroups(v)}
                />
              </div>
              <div className='col'>
                <div className='enable-datalogic'>
                  <Input
                    className='datalogic-toggle'
                    type='toggle'
                    value={siteDatalogicAccess[x.value] === true}
                    onChange={(v) => handleDatalogicToggle(x.value, v)}
                  />
                </div>
              </div>
            </div>
          )}
          </div>

          <Buttons className='flex right'>
            <Button
              className='small primary blue'
              text='Save'
              onClick={() => handleSaveRoles()}
            />

            <Button
              className='small secondary'
              text='Cancel'
              onClick={() => { setSiteRoles([]); setShowRoleSelectorModal(false) }}
            />
          </Buttons>
        </div>
      </Modal>
      </>
  )
}
RoleSelectorModal.propTypes = {
  sites: PropTypes.array,
  roles: PropTypes.array,
  disabled: PropTypes.bool,
  setState: PropTypes.func,
  getState: PropTypes.array,
  setStateGroups: PropTypes.func,
  getStateGroups: PropTypes.array,
  clientGroups: PropTypes.array,
  siteDatalogicAccess: PropTypes.object,
  setSiteDatalogicAccess: PropTypes.func
}

export default RoleSelectorModal
